import React from "react";
import { Link } from "gatsby";
import { graphql, StaticQuery } from "gatsby";
import {
  IoScaleOutline,
  IoInformationCircleOutline,
  IoRibbonOutline,
} from "react-icons/io5";

export const RecommendedCovers = ({
  header = "We've Got you Covered",
  currentProductUrl,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wpPage(id: { eq: "cG9zdDo2Nzk0Ng==" }) {
            id
            title
            layout {
              layoutType
              sections {
                photo {
                  slug
                  altText
                  title
                  localFile {
                    publicURL
                  }
                }
                emphasizedProduct
                photoPosition
                sectionTitle
                sectionTitleLink
                sectionBody
                buttonText
                buttonLink
                postButtonText
                price
                featureItems {
                  warranty
                  weightSupport
                  extraInfo
                }
              }
            }
          }
        }
      `}
      render={({ wpPage }) => (
        <div>
          <h2 className="text-xl lg:text-4xl text-black mb-6">{header}</h2>
          <div
            className={`grid gap-6 lg:mb-24 ${
              currentProductUrl ? "lg:grid-cols-3" : "lg:grid-cols-4"
            }`}
          >
            {wpPage.layout.sections.map((section, index) => {
              if (section.buttonLink === "/" + currentProductUrl + "/") {
                return;
              }

              return (
                <div
                  className="flex flex-col justify-between rounded-lg overflow-hidden text-grey-darkest bg-grey-lighter"
                  key={index}
                >
                  <div>
                    <Link to={`/${section.sectionTitleLink}`}>
                      <img
                        src={section.photo && section.photo.localFile.publicURL}
                        className="h-[200px] w-full object-cover"
                        alt={section.photo && section.photo.alt}
                        title={section.photo && section.photo.title}
                      />
                    </Link>
                    <div className="p-4 lg:p-6 lg:pb-0">
                      {section.emphasizedProduct && (
                        <p className="orange-tag text-sm">Most Popular</p>
                      )}
                      <h3 className="text-lg font-bold">
                        <Link
                          to={`/${section.sectionTitleLink}`}
                          className="no-underline text-black"
                        >
                          {section.sectionTitle}
                        </Link>
                      </h3>
                      {!currentProductUrl && (
                        <div
                          className="text-grey-darker leading-loose"
                          dangerouslySetInnerHTML={{
                            __html: section.sectionBody,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="p-4 lg:p-6">
                    <ul className="mb-6 list-none pl-0 text-grey-darker">
                      <li className="flex items-center mb-2">
                        <IoRibbonOutline
                          className="mr-2 text-black"
                          size={20}
                        />
                        {section.featureItems.warranty}
                      </li>
                      <li className="flex items-center mb-2">
                        <IoScaleOutline className="mr-2 text-black" size={20} />
                        {section.featureItems.weightSupport}
                      </li>
                      <li className="flex items-center mb-2">
                        <IoInformationCircleOutline
                          className="mr-2 text-black"
                          size={20}
                        />
                        {section.featureItems.extraInfo}
                      </li>
                    </ul>
                    <div className="border-t border-grey"></div>
                    <div className="flex justify-between items-center py-3">
                      <div className="text-sm">Starting at</div>
                      <div
                        className="text-2xl font-bold"
                        dangerouslySetInnerHTML={{ __html: section.price }}
                      />
                    </div>

                    <Link
                      to={`/${section.buttonLink}`}
                      className={`block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white px-6 py-2 no-underline rounded text-center`}
                    >
                      {section.buttonText}
                    </Link>
                    <p className="mt-2 text-grey-dark text-sm leading-tight">
                      <small>{section.postButtonText}</small>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    />
  );
};
